import React from 'react'
import { Link } from 'react-router-dom'

function Header() {
  return (
<header class="header">
        <div class="container">
            <div class="header-wrapper">
                <div class="header-logo"><Link to="/">Lucky Legends Social Game</Link></div>
                <nav>
                    <ul class="header-menu">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/games">Play</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                    </ul>
                </nav>
            </div>
        </div>
    </header>
  )
}

export default Header