import React from 'react'
import Header from './Header'
import Footer from './Footer'

function About() {
  return (
<>
<Header/>
<main class="main">
    <section>
        <div class="container">
            <div class="wrapper">
                <div class="image-about">
                    <div>
                    <h1 style={{ color: "#fff" }}>About Us</h1>

                        <p style={{ color: "#fff" }}>Welcome to Lucky Legends Social Game, the perfect hub for casino game
                            enthusiasts to enjoy the excitement of gaming without any financial risks. Join an
                            active community of players, dive into a variety of fresh, engaging games, and compete
                            in tournaments for thrilling rewards. Claim your place in our hall of fame by showcasing
                            your screen name. Our platform offers classic slot games that capture the spirit of real
                            casinos, as well as unique options for added entertainment. Please note: All games are
                            designed for players aged 21 and above, strictly for entertainment. There is no real
                            money gambling or the possibility of winning real-world prizes.</p>
                    </div>
                </div>

            </div>
        </div>
    </section>
</main>
<Footer/>
</>
  )
}

export default About