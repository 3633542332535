import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Cookie() {
  return (
   <>
   <Header/>
   <main class="main">
        <section>
            <div class="container">
                <div class="wrapper-policy">
                    <h1>Cookie Policy for Lucky Legends Social Game.com</h1>

                    <p>This is the Cookie Policy for luckylegendca.com, accessible from
                        https://luckylegendca.com
                    </p>

                    <h2>What Are Cookies</h2>

                    <p>As is common practice with almost all professional websites, this site uses cookies, which
                        are
                        tiny files that are downloaded to your computer, to improve your experience. This page
                        describes
                        what information they gather, how we use it, and why we sometimes need to store these
                        cookies.
                        We will also share how you can prevent these cookies from being stored; however, this may
                        downgrade or 'break' certain elements of the site's functionality.</p>

                    <h3>How We Use Cookies</h3>

                    <p>We use cookies for a variety of reasons detailed below. Unfortunately, in most cases, there
                        are
                        no industry standard options for disabling cookies without completely disabling the
                        functionality and features they add to this site. It is recommended that you leave on all
                        cookies if you are not sure whether you need them or not in case they are used to provide a
                        service that you use.</p>

                    <h3>Disabling Cookies</h3>

                    <p>You can prevent the setting of cookies by adjusting the settings on your browser (see your
                        browser Help for how to do this). Be aware that disabling cookies will affect the
                        functionality
                        of this and many other websites that you visit. Disabling cookies will usually result in
                        also
                        disabling certain functionality and features of this site. Therefore, it is recommended that
                        you
                        do not disable cookies.</p>

                    <h3>The Cookies We Set</h3>

                    <ul>
                        <li><strong>Account related cookies:</strong> If you create an account with us, then we will
                            use
                            cookies for the management of the signup process and general administration. These
                            cookies
                            will usually be deleted when you log out; however, in some cases, they may remain
                            afterwards
                            to remember your site preferences when logged out.</li>

                        <li><strong>Email newsletters related cookies:</strong> This site offers newsletter or email
                            subscription services, and cookies may be used to remember if you are already registered
                            and
                            whether to show certain notifications which might only be valid to
                            subscribed/unsubscribed
                            users.</li>

                        <li><strong>Orders processing related cookies:</strong> This site offers e-commerce or
                            payment
                            facilities, and some cookies are essential to ensure that your order is remembered
                            between
                            pages so that we can process it properly.</li>

                        <li><strong>Surveys related cookies:</strong> From time to time, we offer user surveys and
                            questionnaires to provide you with interesting insights, helpful tools, or to understand
                            our
                            user base more accurately. These surveys may use cookies to remember who has already
                            taken
                            part in a survey or to provide you with accurate results after you change pages.</li>

                        <li><strong>Site preferences cookies:</strong> In order to provide you with a great
                            experience
                            on this site, we provide the functionality to set your preferences for how this site
                            runs
                            when you use it. In order to remember your preferences, we need to set cookies so that
                            this
                            information can be called whenever you interact with a page affected by your
                            preferences.
                        </li>
                    </ul>

                    <h3>Third Party Cookies</h3>

                    <p>In some special cases, we also use cookies provided by trusted third parties. The following
                        section details which third party cookies you might encounter through this site.</p>

                    <ul>
                        <li>This site uses <strong>Google Analytics</strong>, which is one of the most widespread
                            and
                            trusted analytics solutions on the web for helping us understand how you use the site
                            and
                            ways that we can improve your experience. These cookies may track things such as how
                            long
                            you spend on the site and the pages that you visit so we can continue to produce
                            engaging
                            content. For more information on Google Analytics cookies, see the official Google
                            Analytics
                            page.</li>

                        <li>From time to time, we test new features and make subtle changes to the way that the site
                            is
                            delivered. When we are still testing new features, these cookies may be used to ensure
                            that
                            you receive a consistent experience while on the site, ensuring we understand which
                            optimizations our users appreciate the most.</li>

                        <li>The Google AdSense service we use to serve advertising uses a <strong>DoubleClick
                                cookie</strong> to serve more relevant ads across the web and limit the number of
                            times
                            that a given ad is shown to you. For more information on Google AdSense, see the
                            official
                            Google AdSense privacy FAQ.</li>

                        <li>We use adverts to offset the costs of running this site and provide funding for further
                            development. The behavioral advertising cookies used by this site are designed to ensure
                            that we provide you with the most relevant adverts where possible by anonymously
                            tracking
                            your interests and presenting similar things that may be of interest.</li>

                        <li>Several partners advertise on our behalf, and affiliate tracking cookies simply allow us
                            to
                            see if our customers have come to the site through one of our partner sites so that we
                            can
                            credit them appropriately and where applicable allow our affiliate partners to provide
                            any
                            bonus that they may provide you for making a purchase.</li>

                        <li>We also use social media buttons and/or plugins on this site that allow you to connect
                            with
                            your social network in various ways. For these to work, the following social media sites
                            will set cookies through our site which may be used to enhance your profile on their
                            site or
                            contribute to the data they hold for various purposes outlined in their respective
                            privacy
                            policies.</li>
                    </ul>

                    <h3>More Information</h3>

                    <p>Hopefully, that has clarified things for you, and as was previously mentioned if there is
                        something that you aren't sure whether you need or not it's usually safer to leave cookies
                        enabled in case it does interact with one of the features you use on our site.</p>

                    <p>However, if you are still looking for more information, then you can contact us through one
                        of
                        our preferred contact methods:</p>

                    <p>Email: info@luckylegendca.com</p>
                </div>
            </div>
        </section>
    </main>
   <Footer/>
   </>
  )
}

export default Cookie